// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { KyberNetwork, Messages2, Calendar1, Kanban, Profile2User, Bill, UserSquare, ShoppingBag, Category, Location, PercentageSquare } from 'iconsax-react';

// icons
const icons = {
  category: Category,
  applications: KyberNetwork,
  chat: Messages2,
  calendar: Calendar1,
  kanban: Kanban,
  customer: Profile2User,
  invoice: Bill,
  profile: UserSquare,
  ecommerce: ShoppingBag,
  location: Location,
  taxes: PercentageSquare
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const applications = {
  id: 'group-applications',
  title: <FormattedMessage id="applications" />,
  icon: icons.applications,
  type: 'group',
  children: [
    // {
    //   id: 'chat',
    //   title: <FormattedMessage id="chat" />,
    //   type: 'item',
    //   url: '/apps/chat',
    //   icon: icons.chat,
    //   breadcrumbs: false
    // },
    // {
    //   id: 'calendar',
    //   title: <FormattedMessage id="calendar" />,
    //   type: 'item',
    //   url: '/apps/calendar',
    //   icon: icons.calendar
    // },
    // {
    //   id: 'kanban',
    //   title: <FormattedMessage id="kanban" />,
    //   type: 'item',
    //   icon: icons.kanban,
    //   url: '/apps/kanban/board'
    // },
    {
      id: 'customer',
      title: <FormattedMessage id="customer" />,
      type: 'collapse',
      icon: icons.customer,
      children: [
        {
          id: 'customer-list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/apps/customer/customer-list'
        }
        // {
        //   id: 'customer-card',
        //   title: <FormattedMessage id="cards" />,
        //   type: 'item',
        //   url: '/apps/customer/customer-card'
        // }
      ]
    },
    {
      id: 'invoice',
      title: <FormattedMessage id="invoice" />,
      url: '/apps/invoice/dashboard',
      type: 'collapse',
      icon: icons.invoice,
      breadcrumbs: true,
      children: [
        {
          id: 'create',
          title: <FormattedMessage id="create" />,
          type: 'item',
          url: '/apps/invoice/create'
        },
        {
          id: 'details',
          title: <FormattedMessage id="details" />,
          type: 'item',
          url: '/apps/invoice/details/1'
        },
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/apps/invoice/list'
        },
        {
          id: 'edit',
          title: <FormattedMessage id="edit" />,
          type: 'item',
          url: '/apps/invoice/edit/1'
        }
      ]
    },
    // {
    //   id: 'profile',
    //   title: <FormattedMessage id="profile" />,
    //   type: 'collapse',
    //   icon: icons.profile,
    //   children: [
    //     {
    //       id: 'user-profile',
    //       title: <FormattedMessage id="user-profile" />,
    //       type: 'item',
    //       url: '/apps/profiles/user/personal',
    //       breadcrumbs: false
    //     },
    //     {
    //       id: 'account-profile',
    //       title: <FormattedMessage id="account-profile" />,
    //       type: 'item',
    //       url: '/apps/profiles/account/basic'
    //     }
    //   ]
    // },
    {
      id: 'configurations',
      title: <FormattedMessage id="configurations" />,
      type: 'collapse',
      icon: icons.category,
      breadcrumbs: true,
      children: [
        {
          id: 'category',
          title: <FormattedMessage id="category" />,
          type: 'item',
          url: '/apps/category'
        },
        {
          id: 'sub-category',
          title: <FormattedMessage id="sub-category" />,
          type: 'item',
          url: '/apps/subcategory'
        },
        {
          id: 'product-type',
          title: <FormattedMessage id="product-type" />,
          type: 'item',
          url: '/apps/product-type'
        },
        {
          id: 'attribute',
          title: <FormattedMessage id="attribute" />,
          type: 'item',
          url: '/apps/variant'
        },
        {
          id: 'attribute-value',
          title: <FormattedMessage id="attribute-value" />,
          type: 'item',
          url: '/apps/variant-value'
        }
      ]
    },
    {
      id: 'location',
      title: <FormattedMessage id="location" />,
      type: 'collapse',
      icon: icons.location,
      breadcrumbs: true,
      children: [
        {
          id: 'state',
          title: <FormattedMessage id="state" />,
          type: 'item',
          url: '/apps/state'
        },
        {
          id: 'add-state',
          title: <FormattedMessage id="add-state" />,
          type: 'item',
          url: '/apps/add-state'
        },
        {
          id: 'city',
          title: <FormattedMessage id="city" />,
          type: 'item',
          url: '/apps/city'
        },
        {
          id: 'add-city',
          title: <FormattedMessage id="add-city" />,
          type: 'item',
          url: '/apps/add-city',
        }
      ]
    },
    {
      id: 'products',
      title: <FormattedMessage id="products" />,
      type: 'collapse',
      icon: icons.ecommerce,
      children: [
        // {
        //   id: 'products',
        //   title: <FormattedMessage id="products" />,
        //   type: 'item',
        //   url: '/apps/e-commerce/products'
        // },
        // {
        //   id: 'product-details',
        //   title: <FormattedMessage id="product-details" />,
        //   type: 'item',
        //   url: '/apps/e-commerce/product-details/1',
        //   breadcrumbs: false
        // },
        {
          id: 'product-list',
          title: <FormattedMessage id="product-list" />,
          type: 'item',
          url: '/apps/e-commerce/product-list',
          // breadcrumbs: false
        },
        // {
        //   id: 'add-new-product',
        //   title: <FormattedMessage id="add-new-product" />,
        //   type: 'item',
        //   url: '/apps/e-commerce/add-new-product'
        // },
        // {
        //   id: 'checkout',
        //   title: <FormattedMessage id="checkout" />,
        //   type: 'item',
        //   url: '/apps/e-commerce/checkout'
        // }
      ]
    },
    {
      id: 'taxes',
      title: <FormattedMessage id="taxes" />,
      type: 'collapse',
      icon: icons.taxes,
      children: [    
         {
          id: 'tax-list',
          title: <FormattedMessage id="tax-list" />,
          type: 'item',
          url: '/apps/taxes/list-tax'
        }
      ]
    }
  ]
};

export default applications;
